// ../Apis/ShowFavProduct.js
import axios from 'axios';

export const showFavProduct = async (userId) => {
  const token = sessionStorage.getItem('access_token'); // Retrieve the access token
  try {
    const response = await axios.get(
      `https://pos.counterbill.com/connector/api/show-fav-product/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token
          Accept: 'application/json',
        },
      }
    );
    // console.log(response.data);
    
    return response.data;
  } catch (error) {
    console.error('Error retrieving favorite products:', error);
    throw error;
  }
};
