import React, { createContext, useReducer, useContext, useEffect, useState } from 'react';
import { storeCart } from '../Apis/StoreCart'; // Import the storeCart API
import { showCart } from '../Apis/ShowCart'; // Import the showCart API

const CartContext = createContext();

const initialState = {
  items: [], // Initialize items as an empty array
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      const customerId = sessionStorage.getItem('customer_id');

      if (!customerId) {
        window.location.href = "/login";
      }

      const itemsArray = state.items || []; // Ensure items is an array
      console.log(action.payload);
      
      const existingItemIndex = itemsArray.findIndex(item => item.variation_id === action.payload.id);
   
      console.log(existingItemIndex);//why i got -1
      
      let updatedItems;

      if (existingItemIndex >= 0) {
        // If item with the same variation_id exists, increment the quantity
        updatedItems = itemsArray.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, quantity: item.quantity + 1 };
          }
          return item;
        });
      } else {
        // If item doesn't exist, add it with quantity 1
        updatedItems = [...itemsArray, { ...action.payload, quantity: 1 }];
      }

      return { ...state, items: updatedItems };
    }

    case 'REMOVE_FROM_CART': {
      const itemsArray = state.items || []; // Ensure items is an array
      const existingItemIndex = itemsArray.findIndex(item => item.variation_id === action.payload.id);
      let updatedItems = [...itemsArray];

      if (existingItemIndex >= 0) {
        const item = updatedItems[existingItemIndex];

        if (action.payload.removeAll) {
          // Remove the item completely
          updatedItems.splice(existingItemIndex, 1);
        } else if (item.quantity > 1) {
          // If quantity > 1, decrement the quantity
          updatedItems[existingItemIndex] = { ...item, quantity: item.quantity - 1 };
        } else {
          // If quantity is 1, remove the item
          updatedItems.splice(existingItemIndex, 1);
        }
      }

      return { ...state, items: updatedItems };
    }

    case 'CLEAR_CART': {
      return { ...state, items: [] }; // Empty the cart by setting items to an empty array
    }

    case 'SET_CART_ITEMS': {
      return { ...state, items: action.payload || [] }; // Ensure payload is an array
    }

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [cartInitialized, setCartInitialized] = useState(false); // Add flag for initial cart load

  const userId = sessionStorage.getItem('customer_id');

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartItems = await showCart(userId);
        dispatch({ type: 'SET_CART_ITEMS', payload: cartItems });
        setCartInitialized(true); // Set flag to true after initial fetch
      } catch (error) {
        console.error('Error fetching initial cart items:', error);
      }
    };

    if (userId) {
      fetchCartItems();
    }
  }, [userId]);

  useEffect(() => {
    const updateCart = async () => {
      try {
        const cartEncoded = JSON.stringify(state.items || []); // Fallback to empty array
        await storeCart(userId, cartEncoded);
      } catch (error) {
        console.error('Error storing cart:', error);
      }
    };

    if (cartInitialized) { // Only run if the cart has been initialized
      updateCart();
    }
  }, [state.items, userId, cartInitialized]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
