import React, { useState } from 'react';
import checkMobileNumber from '../Apis/CheckMobileNumber';
import storeMobileNumber from '../Apis/storeMobileNumber';
import verifyOtp from '../Apis/VerifyOtp'; // Import the verifyOtp function

const Login = () => {
    const [step, setStep] = useState(1); // Step 1: Mobile Number, Step 2: OTP, Step 3: Name (if needed)
    const [countryCode, setCountryCode] = useState('+974');
    const [mobileNumber, setMobileNumber] = useState('');
    const [name, setName] = useState('');
    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [customerId, setCustomerId] = useState(null);

    // Function to handle mobile number validation and move to the next step
    const handleCheckMobileNumber = async () => {
        setErrorMessage('');
        if (!mobileNumber || !/^\d{7,15}$/.test(mobileNumber)) {
            setErrorMessage('Please enter a valid mobile number (7 to 15 digits).');
            return;
        }

        const fullMobileNumber = `${countryCode}${mobileNumber}`;
        const { exists, data, message } = await checkMobileNumber(fullMobileNumber);

        if (exists) {
            // User exists, store customer ID and move to OTP entry step
            setCustomerId(data.customer_id);
            sessionStorage.setItem('customer_id', data.customer_id);
            sessionStorage.setItem('name', data.name);
            setStep(2); // Move to OTP entry step
        } else {
            setErrorMessage(message);
            // Move to OTP entry step even for new users (to prompt for name and OTP)
            setStep(2);
        }
    };

    // Function to handle OTP verification
    const handleVerifyOtp = async () => {
        setErrorMessage('');
        if (!otp) {
            setErrorMessage('Please enter the OTP.');
            return;
        }

        const fullMobileNumber = `${countryCode}${mobileNumber}`;
        
        try {
            if (customerId) {
                // Verify OTP for existing users using the API
                const verificationResult = await verifyOtp(fullMobileNumber, otp);
                
                if (verificationResult.success) {
                   
                    
                   
                    window.location.href = '/';
                } else {
                 
                     sessionStorage.removeItem('customer_id');
                     sessionStorage.removeItem('name');
                    setErrorMessage('OTP verification failed. Please try again.');
                }
            } else {
                // New user flow
                if (!name) {
                    setErrorMessage('Please enter your name.');
                    return;
                }

                // Call verifyOtp API for the new user
                const verificationResult = await verifyOtp(fullMobileNumber, otp);

                // Store the new user details and simulate OTP verification
               
                
             
                
                if (verificationResult.success) {


                    const data = await storeMobileNumber(fullMobileNumber, name);
                    sessionStorage.setItem('customer_id', data.data.id); // Use data.id
                    sessionStorage.setItem('name', data.data.first_name); // or data.name if you want the full name
            
                   
                    window.location.href = '/';
                } else {
                    setErrorMessage('Invalid OTP. Please try again.');
                }
            }
        } catch (error) {
            setErrorMessage('Failed to verify OTP. Please try again.');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <h1 className="text-3xl font-bold text-center mb-6 text-[#1c9d98]">Login</h1>

                {/* Step 1: Mobile Number Input */}
                {step === 1 && (
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Mobile Number</label>
                        <div className="flex mb-4">
                            <select
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                                className="p-2 border-2 border-[#1c9d98] rounded-l focus:outline-none focus:border-[#1c9d98]"
                            >
                                <option value="+971">+971 (UAE)</option>
                                <option value="+965">+965 (Kuwait)</option>
                                <option value="+966">+966 (Saudi Arabia)</option>
                                <option value="+968">+968 (Oman)</option>
                                <option value="+974">+974 (Qatar)</option>
                                <option value="+973">+973 (Bahrain)</option>
                            </select>
                            <input
                                type="text"
                                value={mobileNumber}
                                onChange={(e) => setMobileNumber(e.target.value)}
                                placeholder="Enter mobile number"
                                className="flex-1 p-2 border-2 border-[#1c9d98] rounded-r focus:outline-none focus:border-[#1c9d98]"
                            />
                        </div>
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <button
                            onClick={handleCheckMobileNumber}
                            className="w-full p-2 bg-[#1c9d98] text-white rounded hover:bg-[#1b8e87] transition duration-200 ease-in-out"
                        >
                            Check Number
                        </button>
                    </div>
                )}

                {/* Step 2: OTP Input */}
                {step === 2 && (
                    <div className="mt-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Enter OTP</label>
                        <input
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                            className="w-full p-2 border-2 border-[#1c9d98] rounded mb-4 focus:outline-none focus:border-[#1c9d98]"
                        />
                        {!customerId && (
                            <>
                                <label className="block text-gray-700 text-sm font-bold mb-2">Your Name</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your name"
                                    className="w-full p-2 border-2 border-[#1c9d98] rounded mb-4 focus:outline-none focus:border-[#1c9d98]"
                                />
                            </>
                        )}
                        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                        <button
                            onClick={handleVerifyOtp}
                            className="w-full p-2 bg-[#1c9d98] text-white rounded hover:bg-[#1b8e87] transition duration-200 ease-in-out"
                        >
                            Verify OTP
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
