// BottomNav.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faShoppingCart, faUser, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import CustomerProfileDropdown from './CustomerProfileDropdown';

const BottomNav = () => {
  const location = useLocation(); // Access the current location
  const [activeMenu, setActiveMenu] = useState(location.pathname); // Set initial state based on the current path
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [userName, setUserName] = useState(''); // State to store user's name
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility

  useEffect(() => {
    setActiveMenu(location.pathname); // Update activeMenu on path change
    
    // Check session storage for user data
    const customerId = sessionStorage.getItem('customer_id');
    const firstName = sessionStorage.getItem('name');
    
    if (customerId && firstName) {
      setIsLoggedIn(true); // User is logged in
      setUserName(firstName); // Set user's name
    } else {
      setIsLoggedIn(false); // User is not logged in
    }
  }, [location.pathname]);

  const handleLogout = () => {
    // Clear session storage and update state
    sessionStorage.removeItem('customer_id');
    sessionStorage.removeItem('name');
    setIsLoggedIn(false);
    setShowDropdown(false); // Close dropdown on logout
  };

  const menuItems = [
    { to: "/", icon: faHome, label: "Menu" },
    { to: "/lookup", icon: faSearch, label: "Lookup" },
    { to: "/cart", icon: faShoppingCart, label: "Cart" },
    { to: "/more", icon: faEllipsisH, label: "More" }
  ];

  return (
    <div className="md:hidden fixed bottom-0 left-0 right-0 bg-[#1c9d98] p-2 flex justify-around shadow-lg">
      {menuItems.map(({ to, icon, label }) => (
        <Link 
          key={to}
          to={to}
          className={`flex flex-col items-center transition-all ease-in duration-300 transform 
                      ${activeMenu === to ? 'bg-white text-[#1c9d98] border-2 border-[#1c9d98] skew-y-3 -translate-y-10' : 'hover:skew-y-3 hover:bg-white hover:text-[#1c9d98] text-white'} 
                      rounded-full w-16 h-16 justify-center relative`}
        >
          <FontAwesomeIcon icon={icon} className="text-2xl" />
          <span className="text-xs">{label}</span>
        </Link>
      ))}
      {isLoggedIn ? (
        <div className="relative">
          <span 
            onClick={() => setShowDropdown(!showDropdown)}
            className="bg-white text-[#1c9d98] rounded-full w-10 h-10 flex items-center justify-center font-bold cursor-pointer"
          >
            {userName.charAt(0)} {/* Display first letter of name */}
          </span>
          {showDropdown && (
            <CustomerProfileDropdown userName={userName} onLogout={handleLogout}  page={"bottom"} onClose={() => setShowDropdown(false)} />
          )}
        </div>
      ) : (
        // Show Login button if user is not logged in
        <Link 
          to="/login"
          className={`flex flex-col items-center transition-all ease-in duration-300 transform 
                      hover:bg-white hover:text-[#1c9d98] text-white rounded-full w-16 h-16 justify-center`}
        >
          <FontAwesomeIcon icon={faUser} className="text-2xl" />
          <span className="text-xs">Login</span>
        </Link>
      )}
    </div>
  );
};

export default BottomNav;
