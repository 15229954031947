import React, { useState } from 'react';
import { useCart } from '../context/CartContext';
import { toast } from 'react-toastify';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaMapMarkerAlt, FaMoneyBillWave, FaAddressCard } from 'react-icons/fa';
import { storeSells } from '../Apis/StoreSells'; // Import the storeSells API function

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const Checkout = () => {
  const { state } = useCart();
  const { dispatch } = useCart();
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [shippingDetails, setShippingDetails] = useState(''); // State for shipping details
  const [mapLocation, setMapLocation] = useState(center);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [loading, setLoading] = useState(false);
  const [isMapSelected, setIsMapSelected] = useState(false); // Track if map was used

  const calculateOverallTotal = () => {
    return state.items.reduce((total, item) => total + (Number(item.unit_price) * item.quantity), 0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate delivery address and marker position
    if (!deliveryAddress || (markerPosition.lat === 0 && markerPosition.lng === 0)) {
      toast.error('Please provide a delivery address and select a location on the map.');
      return;
    }

    setLoading(true); // Start loading

    const sellData = {
      sells: {
        change_return: 0.0,
        contact_id: sessionStorage.getItem('customer_id'), // Get customer ID from session storage
        discount_amount: 0.0,
        discount_type: 'fixed',
        final_total: calculateOverallTotal(),
        id: 0,
        is_quotation: false,
        is_suspend: 0,
        location_id: 1,
        // payments: [
        //   {
        //     account_id: 1,
        //     amount: calculateOverallTotal(),
        //     method: 'due',
        //   },
        // ],
        products: state.items.map(item => ({
          discount_amount: 0.0,
          discount_type: 'fixed',
          enable_stock: true,
          item_tax: 0,
          line_discount_amount: 0.0,
          line_discount_type: 'fixed',
          product_id: item.id,
          product_type: 'single',
          product_unit_id: item.product_unit_id,
          quantity: item.quantity,
          sub_unit_id: 1,
          tax_id: '',
          unit_price: item.unit_price,
          unit_price_inc_tax: item.unit_price_inc_tax,
          variation_id: item.variation_id,
        })),
        res_waiter_id: "0",
        status: "final",
        tax_rate_id: "0",
        types_of_service_id: "5",
        shipping_charges: 0,
        shipping_details: shippingDetails, // Include shipping details
        shipping_status: "ordered",
        shipping_address: deliveryAddress,
      },
    };

    try {
      const response = await storeSells(sellData);
      // console.log('Response from API:', response);
      
      // Handle response based on the success or failure of the API call
      if (response.success) {
        toast.success('Order placed successfully!');
        setDeliveryAddress('');
        setShippingDetails(''); // Reset shipping details
        setMarkerPosition(center);
        setMapLocation(center);
        dispatch({ type: 'CLEAR_CART' });
        setTimeout(() => {
            window.location.href="/";
        }, 4000);
        
      } else {
        toast.error(response.message || 'Order submission failed.');
      }
    } catch (error) {
      console.error('Error while placing order:', error);
      toast.error('Error placing order, please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setMarkerPosition({ lat, lng });
    setDeliveryAddress('Location selected via map'); // Update address field when map is used
    setIsMapSelected(true); // Indicate map was used to select location
  };

  const handleAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
    setIsMapSelected(false); // Indicate user manually changed the address
  };

  const handleShippingDetailsChange = (e) => {
    setShippingDetails(e.target.value); // Update shipping details
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#e0f7fa] to-[#ffffff] flex items-center justify-center p-6">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-xl space-y-6">
        <h2 className="text-4xl font-bold text-[#1c9d98] mb-6 text-center">Checkout</h2>

        {/* Order Product Preview */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Order Summary</h3>
          {state.items.length > 0 ? (
            <table className="w-full text-left border-collapse">
              <thead>
                <tr>
                  <th className="border-b py-2">Product Name</th>
                  <th className="border-b py-2">Quantity</th>
                  <th className="border-b py-2">Unit Price</th>
                  <th className="border-b py-2">Total</th>
                </tr>
              </thead>
              <tbody>
                {state.items.map((item, index) => (
                  <tr key={index}>
                    <td className="py-2 border-b">{item.name}</td>
                    <td className="py-2 border-b">{item.quantity}</td>
                    <td className="py-2 border-b">QR {Number(item.unit_price).toFixed(2)}</td>
                    <td className="py-2 border-b">QR {(Number(item.unit_price) * item.quantity).toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="3" className="py-2 font-bold">Overall Total</td>
                  <td className="py-2 font-bold">QR {calculateOverallTotal().toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>Your cart is empty.</p>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          {/* Delivery Address */}
          <div className="mb-6">
            <label className="block text-sm font-bold mb-2 flex items-center">
              <FaAddressCard className="mr-2 text-[#1c9d98]" />
              Delivery Address
            </label>
            <textarea
              id="address"
              value={deliveryAddress}
              onChange={handleAddressChange} // Update address manually
              required
              className="w-full border border-[#d1d5db] rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition duration-200"
              placeholder="Enter your delivery address or select from map"
              rows="4"
            />
            {isMapSelected && (
              <p className="text-xs text-gray-500 mt-1">* Address updated via map.</p>
            )}
          </div>

          {/* Shipping Details */}
          <div className="mb-6">
            <label className="block text-sm font-bold mb-2 flex items-center">
              <FaAddressCard className="mr-2 text-[#1c9d98]" />
              Shipping Details
            </label>
            <textarea
              id="shippingDetails"
              value={shippingDetails}
              onChange={handleShippingDetailsChange} // Update shipping details
              className="w-full border border-[#d1d5db] rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition duration-200"
              placeholder="Enter any specific shipping instructions or details"
              rows="4"
            />
          </div>

          {/* Google Maps with Draggable Marker */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2 flex items-center">
              <FaMapMarkerAlt className="mr-2 text-[#1c9d98]" />
              Select Delivery Location:
            </h3>
            <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapLocation}
                zoom={10}
              >
                <Marker
                  position={markerPosition}
                  onDragEnd={onMarkerDragEnd} // Update marker position and address
                  draggable
                />
              </GoogleMap>
            </LoadScript>
          </div>

          {/* Payment Method */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2 flex items-center">
              <FaMoneyBillWave className="mr-2 text-[#1c9d98]" />
              Payment Method
            </h3>
            <p>Select a payment method and proceed.</p>
            <select className="w-full border border-[#d1d5db] rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition duration-200">
              <option value="cash">Cash on Delivery</option>
              {/* Add more payment options here if needed */}
            </select>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className={`w-full bg-[#1c9d98] text-white font-bold py-2 rounded-lg hover:bg-[#138a8b] transition duration-200 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            {loading ? 'Placing Order...' : 'Place Order'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Checkout;
