import React, { useState } from 'react';

const CategoryList = ({ categories }) => {
  const [activeCategory, setActiveCategory] = useState('');

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    // Smooth scroll to the category section with an offset
    const element = document.getElementById(category);
    if (element) {
      const offset = 150; // Margin top in pixels
      const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="md:p-4 p-1">
      <h2 className="text-lg font-bold mb-4 md:block hidden">Categories</h2>
      <ul className='md:block flex'>
        {categories.map((category, index) => (
          <li key={index} className="py-2">
            <button 
              onClick={() => handleCategoryClick(category)}
              className={`w-full text-left p-2 rounded-md transition whitespace-nowrap 
                          ${activeCategory === category ? 'bg-[#1c9d98] text-white' : 'hover:bg-[#e0e0e0]'}`}
            >
              {category}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
