// src/context/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import fetchToken from '../Apis/SelfLogin';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkLoginStatus = async () => {
            const token = sessionStorage.getItem('access_token');

            if (!token) {
                await login(); // Call login function
            } else {
                setIsLoggedIn(true);
                setLoading(false);
                // console.log('User is already logged in.');
            }
        };

        checkLoginStatus();
    }, []);

    const login = async () => {
        try {
            await fetchToken();
            setIsLoggedIn(true);
            // console.log('Login successful.');
        } catch (error) {
            console.error('Login failed:', error);
            setIsLoggedIn(false);
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
        setIsLoggedIn(false);
        // console.log('User logged out.');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the Auth context
export const useAuth = () => {
    return useContext(AuthContext);
};
