import React from 'react';
import { useCart } from '../context/CartContext';
import { toast } from 'react-toastify'; // Import toast
import { Link } from 'react-router-dom';

const Cart = () => {
  const { state, dispatch } = useCart();
// console.log(state.items);


  const handleRemoveAll = (itemId) => {
    const item = state.items.find(item => item.variation_id === itemId);
    if (item) {
      dispatch({ type: 'REMOVE_FROM_CART', payload: { id: itemId, removeAll: true } });
      toast.error(`${item.name} has been removed from the cart.`); // Show toast notification
    }
  };

  const handleIncrease = (itemId) => {
    dispatch({ type: 'ADD_TO_CART', payload: { id: itemId } });
  };

  const handleDecrease = (itemId) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { id: itemId } });
  };

  return (
    <div className="p-6 min-h-screen bg-gray-100">
      <h2 className="text-3xl font-bold text-[#1c9d98] mb-6 text-center">Shopping Cart</h2>
      {state.items.length === 0 ? (
        <p className="text-gray-500 text-center text-lg">Your cart is empty.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {state.items.map((item, index) => {
            const price = parseFloat(item.price) || 0;

            return (
              <div key={index} className="bg-white shadow-lg rounded-lg p-4 flex flex-col transition transform hover:scale-105">
                <img 
                  src={item.image} 
                  alt={item.name} 
                  className="w-full h-40 object-cover rounded-t-lg mb-4"
                />
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">{item.name}</h3>
                  <span className="text-[#1c9d98] font-bold text-xl">QR {price.toFixed(2)}</span>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <button 
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-700 transition duration-200 shadow-md"
                    onClick={() => handleDecrease(item.variation_id)}
                    disabled={item.quantity <= 1}
                  >
                    -
                  </button>
                  <span className="mx-4 text-lg font-bold">{item.quantity}</span>
                  <button 
                    className="bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-700 transition duration-200 shadow-md"
                    onClick={() => handleIncrease(item.variation_id)}
                  >
                    +
                  </button>
                </div>
                <button 
                  className="mt-4 bg-red-500 text-white px-3 py-2 rounded-lg hover:bg-red-700 transition duration-200 shadow-md"
                  onClick={() => handleRemoveAll(item.variation_id)}
                >
                  Remove All
                </button>
              </div>
            );
          })}
        </div>
      )}
      <div className="mt-6 text-center">
      <Link to="/checkout" className="bg-[#1c9d98] text-white px-6 py-3 rounded-lg text-xl hover:bg-[#168c8b] transition duration-200 shadow-lg">
  Proceed to Checkout
</Link>

      </div>
    </div>
  );
};

export default Cart;
