import React from 'react';

const Lookup = () => {

  return (
    <div className="p-4 min-h-screen">
    </div>
  );
};

export default Lookup;
