import React from 'react';

const More = () => {
  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">More Information</h2>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">About Us</h3>
        <p>
          Welcome to our cake shop! We specialize in creating delicious and beautifully crafted cakes for all occasions. 
          Our team is passionate about baking and dedicated to using high-quality ingredients to ensure every bite is a 
          delightful experience. Whether you're celebrating a birthday, wedding, or any special event, we are here to 
          make it memorable.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">Disclaimer</h3>
        <p>
          Please note that while we take great care in preparing our cakes, our products may contain or come into contact 
          with common allergens such as nuts, gluten, and dairy. We recommend that customers with food allergies consult 
          with our staff before placing an order. All information provided is for informational purposes only and we 
          are not liable for any dietary issues that may arise.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">Our Products</h3>
        <p>
          Our selection includes a wide variety of cakes, cupcakes, and pastries. From classic flavors like chocolate and 
          vanilla to unique combinations such as red velvet with cream cheese frosting, we have something for everyone. 
          We also offer custom cake designs to fit your specific needs and preferences.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">Customer Reviews</h3>
        <p>
          "The cake I ordered for my daughter's birthday was absolutely stunning and delicious! Everyone loved it!" - Sarah K.
        </p>
        <p>
          "I can't recommend this cake shop enough! The flavors are out of this world!" - Michael R.
        </p>
        <p>
          "Exceptional service and the best cakes in town!" - Jessica L.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">FAQs</h3>
        <p>
          <strong>Q: Do you offer gluten-free or vegan options?</strong>
        </p>
        <p>A: Yes, we do! Please inquire about our gluten-free and vegan cake options.</p>
        
        <p>
          <strong>Q: How far in advance do I need to place an order?</strong>
        </p>
        <p>A: We recommend placing orders at least 48 hours in advance for standard cakes.</p>
        
        <p>
          <strong>Q: Can I customize my cake?</strong>
        </p>
        <p>A: Absolutely! We love creating custom cakes tailored to your specifications.</p>
      </section>

      {/* Additional Sections */}

      <section className="mb-6">
        <h3 className="text-lg font-semibold">Our Baking Process</h3>
        <p>
          At our cake shop, we believe that great cakes begin with the finest ingredients. 
          Our baking process involves carefully selecting fresh, high-quality ingredients and 
          following time-tested recipes. Each cake is crafted with precision, allowing for the 
          perfect balance of flavor and texture. We bake our cakes in small batches to ensure 
          freshness and quality, and we take pride in every detail—from the lightness of the sponge 
          to the richness of the frosting. Our team is committed to creating cakes that not only 
          taste amazing but also look stunning.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">Sustainability Practices</h3>
        <p>
          We are committed to being environmentally responsible. Our cake shop actively practices 
          sustainability by sourcing ingredients from local farms and suppliers whenever possible. 
          We use eco-friendly packaging materials and minimize waste in our operations. Additionally, 
          we strive to reduce our carbon footprint by optimizing our delivery routes and promoting 
          reusable containers. Our goal is to create delicious cakes while respecting and preserving 
          our planet for future generations.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-semibold">Contact Us</h3>
        <p>
          We would love to hear from you! Whether you have questions about our cakes, want to place an order, 
          or need assistance with customization, feel free to reach out. You can contact us via email at 
          <strong> info@cakeshop.com</strong> or call us at <strong>(123) 456-7890</strong>. 
          Additionally, you can visit us at our shop located at <strong>123 Cake Street, Sweet Town</strong>. 
          Our friendly staff is here to help you with all your cake needs!
        </p>
      </section>
    </div>
  );
};

export default More;
