import React, { useState, useEffect, useRef } from 'react';

const SearchBar = () => {
  const [showInput, setShowInput] = useState(true);
  const searchBarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (searchBarRef.current) {
        // Check the width of the search bar
        const width = searchBarRef.current.offsetWidth;
        setShowInput(width >= 400);
      }
    };

    // Set initial visibility based on the initial width
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div ref={searchBarRef} className="relative w-full max-w-md">
      {showInput && (
        <input
          type="text"
          placeholder="Search for cakes..."
          className="w-full p-2 pl-10 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition duration-300 text-[#1c9d98]"
        />
      )}
      <span className={`absolute left-2 top-1/2 transform -translate-y-1/2 ${showInput ? 'text-gray-500' : 'text-white'}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-4.35-4.35M11 4a7 7 0 100 14 7 7 0 000-14z"
          />
        </svg>
      </span>
    </div>
  );
};

export default SearchBar;
