// VerifyOtp.js
const verifyOtp = async (mobileNumber, otp) => {
    // Simulate the OTP verification
    const dummyOtp = '123456'; // Your dummy OTP

    // Simulating a delay for the verification process (optional)
    await new Promise(resolve => setTimeout(resolve, 1000));

    return {
        success: otp === dummyOtp, // Check if the provided OTP matches the dummy OTP
        // name: otp === dummyOtp ? 'John Doe' : '', // Return a dummy name if OTP is correct
    };
};

export default verifyOtp;
