import React, { useState, useEffect } from 'react';
import CategoryList from '../components/CategoryList';
import CakeCard from '../components/CakeCard';
import axios from 'axios';

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState({});
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('https://pos.counterbill.com/connector/api/product', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });
        const data = response.data.data;
        // console.log(data);

        const categoryProducts = {};

        data.forEach(product => {
          if (product.category && product.category.name) {
            const categoryName = product.category.name;
            if (!categoryProducts[categoryName]) {
              categoryProducts[categoryName] = [];
            }

            if (product.type === 'single') {
              // Single product
              categoryProducts[categoryName].push({
                id: product.id,
                name: product.name,
                type: product.type,
                price: product.product_variations[0]?.variations[0]?.sell_price_inc_tax,
                unit_price: product.product_variations[0]?.variations[0]?.default_purchase_price,
                variation_id: product.product_variations[0]?.id,
                unit_price_inc_tax: product.product_variations[0]?.variations[0]?.dpp_inc_tax,
                product_unit_id: product.unit?.id,
                image: product.image_url || '',
              });
            } else if (product.type === 'variable') {
              let variationName;
              // Variable product - each variation is treated as a unique product
              product.product_variations.forEach(variation => {
                 variationName=variation.name;
                variation.variations.forEach(varDetail => {
                  categoryProducts[categoryName].push({
                    id: product.id, // Keep the same product ID to relate it back to the original product
                    product_name:product.name,
                    name: `${product.name} - ${variationName}`, // Variation name for uniqueness
                    type: product.type,
                    price: varDetail.sell_price_inc_tax,
                    unit_price: varDetail.default_purchase_price,
                    variation_id: variation.id,
                    unit_price_inc_tax: varDetail.dpp_inc_tax,
                    product_unit_id: product.unit?.id,
                    image: product.image_url || '',
                  });
                });
              });
            }
          }
        });
console.log(categoryProducts);

        setCategories(Object.keys(categoryProducts));
        setProductsByCategory(categoryProducts);
        setLoading(false); // Data fetching is done, set loading to false
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false); // Stop the loading state even on error
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="md:flex">
      <div className="fixed md:w-1/4 w-full z-10 md:h-screen md:p-4 bg-[#f9f9f9] border-r overflow-y-auto scrollbar">
        <CategoryList categories={categories} />
      </div>

      <div className="flex-grow md:ml-[25%] md:p-4 pt-20 overflow-y-auto min-h-screen">
        {loading ? (
          // Improved loading spinner
          <div className="flex justify-center items-center h-full">
            <div className="loader"></div> {/* Loading spinner */}
          </div>
        ) : (
          // Display the products when loading is complete
          Object.entries(productsByCategory).map(([category, cakes]) => (
            <div id={category} key={category} className="mb-8">
              <h2 className="text-2xl font-bold text-center mb-4">{category} Cakes</h2>
              <div className="flex justify-center flex-wrap gap-4">
                {cakes.map(cake => (
                  <CakeCard key={cake.variation_id} cake={cake} />
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Home;
