import React, { createContext, useContext, useState, useEffect } from 'react';
import { showFavProduct } from '../Apis/ShowFavProduct';

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const userId = sessionStorage.getItem('customer_id');

  useEffect(() => {
    const fetchFavorites = async () => {
      if (userId) {
        try {
          const favData = await showFavProduct(userId);
          if (favData.data) {
            setFavorites(favData.data.map(id => id.toString())); // Assuming favData.data contains product IDs
          }
        } catch (error) {
          console.error('Error fetching favorites:', error);
        }
      }
    };

    fetchFavorites();
  }, [userId]);

  return (
    <FavoritesContext.Provider value={{ favorites, setFavorites }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  return useContext(FavoritesContext);
};
