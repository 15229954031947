// CustomerProfileDropdown.js
import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHistory, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Importing Font Awesome icons

const CustomerProfileDropdown = ({ userName, onLogout, page, onClose }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose(); // Call the onClose prop to hide the dropdown
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div 
      ref={dropdownRef} // Attach the ref to the dropdown
      className={`absolute ${page === "bottom" ? "bottom-16 right-0" : "right-0 mt-2"} w-48 bg-gradient-to-r from-[#e0f7fa] to-[#b2ebf2] text-[#1c9d98] shadow-lg rounded-lg z-50 overflow-hidden transition-all duration-300`}
    >
      <div className="p-4 border-b">
        <h4 className="text-lg font-semibold text-center">{userName}</h4>
      </div>
      <div className="p-2">
        <ul>
          <li className="flex items-center p-2 hover:bg-[#b2ebf2] cursor-pointer transition duration-200 hover:rounded-lg">
            <FontAwesomeIcon icon={faHeart} className="mr-2" /> <span>Favorite</span>
          </li>
          <li className="flex items-center p-2 hover:bg-[#b2ebf2] cursor-pointer transition duration-200 hover:rounded-lg">
            <FontAwesomeIcon icon={faHistory} className="mr-2" /> <span>Order History</span>
          </li>
        </ul>
      </div>
      <div className="p-2 border-t">
        <button
          onClick={onLogout}
          className="flex items-center w-full text-left text-red-600 hover:bg-red-100 rounded-md p-2 transition duration-200 hover:rounded-lg"
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default CustomerProfileDropdown;
